<template>
  <v-row fill-height align="center" justify="center" no-gutters>
    <v-col
      v-if="getenableADA"
      class="d-flex mt-3 justify-center align-center"
      cols="12"
    >
      <span class="text-h3 font-weight-bold">
        {{ getPageHeader }}
      </span>
    </v-col>

    <v-col cols="6">
      <v-card flat>
        <v-card-text class="d-flex justify-center">
          <!-- <v-text-field
            solo
            :placeholder="$i18n.t('placeHolder')"
            class="text-h5 containerBorder"
            outlined
            rounded
            height="55px"
            v-model="searchKeyword"
            hide-details
            :append-icon="expand ? '' : 'mdi-magnify'"
            @click:append-outer="expand = false"
            @click="expand = true"
            :value="searchKeyword"
            
            clearable
          >
          </v-text-field> -->
          <template v-if="!getenableADA">
            <v-text-field
              :placeholder="$i18n.t('placeHolder')"
              class="text-h5"
              hide-details
              outlined
              height="55px"
              rounded
              v-model="searchKeyword"
              @click:append-outer="expand = false"
              @click="expand = true"
              :value="searchKeyword"
              clear-icon="mdi-close-circle"
              clearable
            >
            </v-text-field>

            <template v-if="!getKioskProfile.data().physicalKeyboard">
              <v-icon
                size="35"
                class="ml-2"
                v-if="!expand"
                @click="expand = !expand"
                >mdi-keyboard-outline</v-icon
              >
              <v-icon size="35" class="ml-2" v-else @click="expand = !expand"
                >mdi-keyboard-off-outline</v-icon
              >
            </template>
          </template>
        </v-card-text>
        <v-expand-transition>
          <v-card-text
            class="d-flex justify-center ma-0 pa-0"
            v-if="expand && !getKioskProfile.data().physicalKeyboard"
          >
            <SimpleKeyboard
              @onChange="onChange"
              @onKeyPress="onKeyPress"
              :input="searchKeyword"
              class="keyboard"
            >
            </SimpleKeyboard>
          </v-card-text>
        </v-expand-transition>
      </v-card>
    </v-col>
    <v-col cols="12" align="center" @click="expand = false">
      <v-card class="pa-4 containerBorder" width="95%" flat>
        <v-data-table
          hide-default-footer
          :items-per-page="-1"
          :height="viewBoxHeight"
          :headers="header"
          :items="list"
          :loading="loading"
          loading-text="Fetching latest data. Please wait..."
          fixed-header
          @click:row="getRowInfo()"
        >
          <template v-slot:item.caseNumber="{ item }">
            <strong>{{ item.caseNumber }}</strong>
          </template>
          <template v-slot:item.partyName="{ item }">
            <div class="my-7" v-if="item.partyName === ''">-</div>
            <div class="my-7" v-else>
              {{ item.partyName }}
            </div>
          </template>
          <template v-slot:item.judgeName="{ item }">
            <span v-if="!item.judgeName">-</span>
            <span v-else-if="item.isAJudge"
              >{{ $t("judge") }} {{ item.judgeName }}</span
            >
            <span v-else>{{ $t("ho") }} {{ item.judgeName }}</span>
          </template>
          <template v-slot:item.hearingLocation="{ item }">
            <v-btn
              @click="handleCourtLocation(item.hearingLocation)"
              width="100%"
              elevation="0"
              color="primary"
            >
              <v-icon left>mdi-gesture-tap</v-icon>
              {{ item.hearingLocation | trimLength }} - {{ item.floorName }}
            </v-btn>
          </template>
          <template v-slot:item.hearingTime="{ item }">
            <span>{{ $t("day") }} - {{ item.hearingTime | dateAndTime }}</span>
          </template>

          <!-- <template v-if="this.getNetworkConnection && list.length === 0" v-slot:no-data>
            <div
              class="text-center pa-4 d-flex flex-column justify-center align-center"
            >
              <v-icon color="primary" size="48"
                >mdi-alert-circle-outline</v-icon
              >
              <p class="text-h6 mt-3" v-html="noDataMessage"></p>
            </div>
          </template> -->
        </v-data-table>

        <v-card-text class="ma-0 pb-0">
          <template v-if="this.getKioskProfile.data().locationCode !== 'PDCAK'">
            <span class="mr-5 text-h6">{{ $t("noCase") }} </span>
            <v-btn color="primary" class="ml-5" @click="showMap()"
              ><v-icon left>mdi-gesture-tap</v-icon
              >{{ getDefaultLocation }}</v-btn
            >
          </template>
          <template v-else>
            <span class="mr-5 text-h6">
              {{ $t("noCase") }}
              <strong>{{ $i18n.t("assistanceMsg") }}</strong>
            </span>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SimpleKeyboard from "@/components/SimpleKeyboard";
import * as fb from "@/firebase";
import router from "@/router";
import { mapGetters } from "vuex";
import moment from "moment";
import MiniSearch from "minisearch";
export default {
  name: "hearingList",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      timeoutHours: 0,
      expand: false,
      searchKeyword: null,
      viewBoxHeight: 600,
      loading: true,
      header: [
        {
          text: this.$i18n.t("headerCaseNumber"),
          align: "center",
          value: "caseNumber",
          width: "200",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: this.$i18n.t("hearderPartyName"),
          align: "start",
          value: "partyName",
          width: "650",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: this.$i18n.t("judgeHeader"),
          align: "center",
          value: "judgeName",
          width: "300",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: this.$i18n.t("headerCourtRoom"),
          align: "center",
          value: "hearingLocation",
          width: "300",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: this.$i18n.t("headerTime"),
          align: "center",
          value: "hearingTime",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "hearingInfo",
      "getMaps",
      "getDefaultLocation",
      "getKioskProfile",
      "getNetworkConnection",
      "getAfterHours",
      "getSessionEnded",
      "isUserLooking",
      "getTouchCount",
      "getSessionId",
      "getPageHeader",
      "getenableADA",
    ]),
    list() {
      if (this.searchKeyword === "" || this.searchKeyword === null) {
        this.hearingInfo.forEach((hearing) => {
          this.$store
            .dispatch("findMap", hearing.hearingLocation)
            .then((response) => {
              console.log("Response from find map:", response);
              hearing.hearingLocation = response.map[0].mapName;
              hearing.floorName = response.floor[0].floorName;
            });
        });
        return this.hearingInfo.slice().sort(this.customSort);
      } else {
        let miniSearch = new MiniSearch({
          fields: ["partyName", "caseNumber"],
          storeFields: [
            "partyName",
            "caseNumber",
            "hearingTime",
            "judgeName",
            "hearingLocation",
            "floorName",
          ],
          searchOptions: {
            fuzzy: 0.2,
            prefix: true,
          },
        });

        miniSearch.addAll(this.hearingInfo);

        let results = miniSearch.search(this.searchKeyword.toLowerCase());
        console.log("Result on search: ", results);
        return results;
      }
    },
    // noDataMessage() {
    //   if (this.list.length === 0 && !this.getNetworkConnection) {
    //     return this.$i18n.t("noInternetMsg")
    //   }
    //   return this.$i18n.t("noInternetMsg");
    // },
  },
  watch: {
    expand(n, o) {
      this.$store.commit("setRequestQna", false);
      this.$store.commit("setIsThinking", false);
      this.$store.commit("setSpeechRecognitionHandler", false);
      if (n && !this.getKioskProfile.data().physicalKeyboard) {
        this.viewBoxHeight = 370;
      } else {
        this.viewBoxHeight = 600;
        //user session logging
        // this.$store.commit("setTouchRequest", {
        //   module: "Todays Hearing",
        //   action: `Search keyword - " ${this.searchKeyword} "`,
        //   response: `Found ${this.list.length} records.`,
        //   timeStamp: new Date(),
        //   requestType: "Touch",
        // });

        let date = new Date();
        this.$store.dispatch("addSessionDataToStrapi", {
          applicationId: this.getKioskProfile.data().applicationId,
          actionType: "Touch",
          applicationSessionId: this.getSessionId,
          timeStamp: date.toISOString(),
          sessionPayload: {
            module: "Todays Hearing",
            action: `Search keyword - " ${this.searchKeyword} "`,
            response: `Found ${this.list.length} records.`,
            timeStamp: date.toISOString(),
          },
        });
      }
    },

    getSessionEnded(newVal) {
      console.log("value changed for session Ended", newVal);
      if (newVal === true) {
        this.searchKeyword = null; // Clear the search input
        this.$nextTick(() => {
          const table = document.querySelector(".v-data-table__wrapper");
          if (table) {
            table.scrollTop = 0; // Scroll table to the top
          }
        });
      }
      this.$store.commit("setSessionEnded", false);
    },
  },
  methods: {
    getRowInfo() {
      let date = new Date();
      console.log("Data Table Clicked.");
      this.$store.commit("setTouchCount", this.getTouchCount + 1);
      if (this.getTouchCount === 1) {
        this.$store.dispatch("addSessionDataToStrapi", {
          applicationId: this.getKioskProfile.data().applicationId,
          actionType: "Session Started",
          applicationSessionId: this.getSessionId,
          timeStamp: date.toISOString(),
          sessionPayload: {
            module: "Default",
            action: "Touch Detected",
            response: "User Session Started",
            timeStamp: date.toISOString(),
          },
        });
      }
    },
    handleScroll(event) {
      // Log or handle the scroll event
      console.log("User scrolled the table", event.target.scrollTop);

      // If getTouchCount is 0, set the timer
      if (this.getTouchCount === 0) {
        clearTimeout(this.timeoutHours);
        this.timeoutHours = setTimeout(() => {
          //this.$store.dispatch("resetKiosk");

          // Scroll the table to the top
          const tableWrapper = this.$el.querySelector(".v-data-table__wrapper");
          if (tableWrapper) {
            tableWrapper.scrollTop = 0;
          }
        }, 30000);
      } else {
        clearTimeout(this.timeoutHours);
      }
    },
    showMap() {
      let date = new Date();
      let mapPath = this.getMaps.get(this.getDefaultLocation.toLowerCase());
      let floorName = this.$i18n
        .t("floors")
        .filter((floor) => floor.floorNumber === mapPath.mapFloor);

      this.$store.dispatch("openImageViewer", {
        name: mapPath.mapName,
        url: mapPath.mapImage,
        floor: floorName[0].floorName,
        showQR: false,
        qrUrl: "",
      });
      this.$store.dispatch("avatarSpeak", mapPath.speech);
      this.tab = mapPath.mapFloor - 1;
      this.$store.dispatch("addSessionDataToStrapi", {
        applicationId: this.getKioskProfile.data().applicationId,
        actionType: "Touch",
        applicationSessionId: this.getSessionId,
        timeStamp: date.toISOString(),
        sessionPayload: {
          module: "Today's Hearing",
          action: "View Map",
          response: mapPath.mapName,
          timeStamp: date.toISOString(),
        },
      });
    },
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      this.$store.commit("setRequestQna", false);
      this.$store.commit("setIsThinking", false);
      this.$store.commit("setSpeechRecognitionHandler", false);
      console.log("button", button);
    },
    onInputChange(input) {
      this.searchKeyword = input.target.value;
    },
    handleCourtLocation(location) {
      console.log("Location: ", location);
      this.$store.commit("setRequestQna", false);
      this.$store.commit("setIsThinking", false);
      this.$store.commit("setSpeechRecognitionHandler", false);
      // window.setPlayerVolume(0);
      // window.stopSpeech();
      this.$i18n.t("maps").forEach((mapper) => {
        if (
          mapper.mapName.toLowerCase().trim() === location.toLowerCase().trim()
        ) {
          var floorName = this.$i18n
            .t("floors")
            .filter((floor) => floor.floorNumber === mapper.mapFloor);
          console.log(floorName[0].floorName, "got floor Name");
          console.log("Mappath data: ", mapper);
          this.$store.dispatch("openImageViewer", {
            name: mapper.mapName,
            url: mapper.mapImage,
            floor: floorName[0].floorName,
            showQR: false,
            qrUrl: "",
          });
          this.$store.dispatch("avatarSpeak", mapper.speech);
          this.tab = mapper.mapFloor - 1;
        }
      });
      //this.$store.dispatch("requestQnA", location);
      //user session logging
      // this.$store.commit("setTouchRequest", {
      //   module: "Todays Hearing",
      //   action: "Show Map",
      //   response: location,
      //   timeStamp: new Date(),
      //   requestType: "Touch",
      // });
      let date = new Date();
      this.$store.dispatch("addSessionDataToStrapi", {
        applicationId: this.getKioskProfile.data().applicationId,
        actionType: "Touch",
        applicationSessionId: this.getSessionId,
        timeStamp: date.toISOString(),
        sessionPayload: {
          module: "Todays Hearing",
          action: `Show Map`,
          response: location,
          timeStamp: date.toISOString(),
        },
      });
    },
    customSort(a, b) {
      if (a.partyName === "" && b.partyName === "") {
        return 0;
      } else if (a.partyName === "") {
        return -1;
      } else if (b.partyName === "") {
        return 1;
      } else {
        return a.partyName.localeCompare(b.partyName);
      }
    },
    formatTime(inputTime) {},
  },
  filters: {
    dateAndTime(val) {
      if (!val) {
        return "-";
      }

      return `${moment(val, ["hmmA", "h:mmA"]).format("h:mm A")}`;
      //return 'Today - ' + parsedTime.toLocaleString('en-US', options);
    },
    trimLength(val) {
      if (val === null || val === undefined) return val;
      if (val.length <= 30) {
        return val;
      }
      return `${val.substring(0, 23)}...`;
    },
  },
  mounted() {
    // this.getKioskProfile.data().applicationId

    // fb.kioskCollection.where("env","==","Test").get().then( snapshot => {

    //  -- use this for now for Test and Prod
    //   fb.kioskCollection.get().then( snapshot => {
    //   snapshot.docs.forEach(doc => {
    //     console.log("Data for Each Prod Kiosk:",doc.data());
    //     const modules = doc.data().modules;
    //     if (modules.includes('hearing')) {
    //       // console.log(doc.data().name,"Kiosk with hearing module right now");
    //         fb.kioskCollection.doc(doc.id).update({
    //             pullFiles: true
    //         });
    //   }
    //   })
    // })

    const tableWrapper = this.$el.querySelector(".v-data-table__wrapper");
    if (tableWrapper) {
      tableWrapper.addEventListener("scroll", this.handleScroll);
    }

    console.log(this.hearingInfo, "This is Hearing Info");
    if (this.hearingInfo.length === 0 && this.getNetworkConnection) {
      const today = new Date();
      // Take Friday if Monday else one day before the day.
      today.setDate(today.getDate() - (today.getDay() === 1 ? 3 : 1));
      const datePullFiles = today.toISOString().split("T")[0];

      this.$store.dispatch("verifyFile", datePullFiles).then((response) => {
        if (response) {
          this.loading = false;
        } else {
          this.$store.dispatch("avatarSpeak", this.$i18n.t("fetchingHearing"));
        }
      });

      // listen to pullfile changes from the firestore db.
      // this.$store.dispatch("listenToPullFilesChange");
    } else {
      this.loading = false;
    }
  },
};
</script>

<style>
.hg-theme-default {
  background-color: #bcbcbc !important;
  font-weight: bolder !important;
  font-size: x-large !important;
}

.v-data-table__wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #d37444 !important;
  width: 10px;
}

.v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #d37444 !important;
}
</style>

<i18n>
  {
    "en": {
        "judge":"Judge",
        "ho":"Hearing Officer",
        "day":"Today",
        "placeHolder": "Tap to search by Full Name or Case Number",
        "noCase": "Can't find your case?",
        "fetchingHearing":"Error fetching hearing data. Please go to the Court Administration Office on 4th floor.",
        "noInternetMsg":"Due to internet connectivity issue, todays hearing list is currently unavailable.For assistance with criminal cases, please visit the Clerk of Courts on the 1st floor.For all other matters, kindly proceed to the Court Administration Office on the 4th floor.",
        "assistanceMsg" : "Please visit someone at the window for assistance.",
        "headerTime":"Date/Time",
        "headerCourtRoom":"Court Room Location",
        "hearderPartyName":"Parties Name",
        "headerCaseNumber":"Case Number",
        "judgeHeader": " Judge Name"
      },
    "es":{
        "judge":"Jueza",
        "ho":"Oficial de audiencia",
        "day":"Hoy",
        "placeHolder": "Toque para buscar por nombre completo o número de caso",
        "noCase": "¿No puedes encontrar tu caso?",
        "fetchingHearing": "Error al recuperar los datos de la audición. Diríjase a la Oficina de Administración del Tribunal en el 4to piso.",
        "noInternetMsg":"Debido a problemas de conectividad a Internet, la lista de audiencias de hoy no está disponible actualmente. Para obtener ayuda con casos penales, visite al Secretario del tribunal en el primer piso. Para todos los demás asuntos, diríjase a la Oficina de administración del tribunal en el cuarto piso.",
        "assistanceMsg":"Visite a alguien en la ventana para obtener ayuda.",
        "headerTime":"Fecha/Hora",
        "headerCourtRoom":"Ubicación de la sala del tribunal",
        "hearderPartyName":"Nombre de las partes",
        "headerCaseNumber":"Número de caso",
        "judgeHeader": "Nombre del Juez"
      }
  }
</i18n>
