import axios from "axios";
const Papa = require("papaparse");
const fs = require("fs");
import i18n from "../../i18n";
import * as fb from "@/firebase/index.js";
import { floor } from "@tensorflow/tfjs-core";
// import { time } from "console";
export default {
  state: {
    hearingInfo: [],
    lastFetched: null,
    intervalTimer: 300000,
    // hearingLocation: {
    //   "Courtroom 1": "Courtroom 1",
    //   "Courtroom 2": "Courtroom 2",
    //   "Courtroom 3": "Courtroom 3",
    //   "Courtroom 4": "Courtroom 4",
    //   "Courtroom 5": "Courtroom 5",
    //   "Courtroom 6": "Courtroom 6",
    //   "Courtroom 7": "Courtroom 7",
    //   "Courtroom 8": "Courtroom 8",
    //   "Courtroom 10": "Courtroom 10",
    //   "Courtroom 11": "Courtroom 11",
    //   "Courtroom 12": "Courtroom 12",
    //   "Courtroom 14": "Courtroom 14",
    //   "Courtroom 15": "Courtroom 15",
    //   "Courtroom 16": "Courtroom 16",
    //   "Courtroom 17": "Courtroom 17",
    //   "Courtroom 18": "Courtroom 18",
    //   "Courtroom 19": "Courtroom 19",
    // },
    judgeCourtRoomDetails: {
      wheatcraft: "Courtroom 1",
      ryan: "Courtroom 3",
      binder: "Courtroom 4",
      hall: "Courtroom 5",
      black: "Courtroom 6",
      royer: "Courtroom 7",
      maddox: "Courtroom 8",
      carmody: "Courtroom 10",
      forzato: "Courtroom 11",
      mccabe: "Courtroom 12",
      sondergaard: "Courtroom 14",
      verwey: "Courtroom 2",
      rovito: "Courtroom 16",
      sommer: "Courtroom 15",
      platt: "Courtroom 15",
      cody: "Courtroom 15",
      bortner: "Courtroom 15",
    },
  },
  getters: {
    hearingInfo: (state) => state.hearingInfo,
    lastFetched: (state) => state.lastFetched,
    // getHearingLocation: (state) => state.hearingLocation,
    intervalTimer: (state) => state.intervalTimer,
  },
  mutations: {
    setHearingInfo(state, hearingObj) {
      state.hearingInfo = hearingObj;
    },
    emptyHearingInfo(state) {
      state.hearingInfo = [];
    },
    setLastFetched(state, fetchObj) {
      state.lastFetched = fetchObj;
    },
    setIntervalTimer(state, timer) {
      state.intervalTimer = timer;
    },
  },
  actions: {
    courtroomLocations() {
      return new Promise((resolve, reject) => {
        try {
          // console.log("Maps: ", i18n.t("maps"));
          const result = i18n
            .t("maps")
            .filter((map) => map.showOnHomepage === true)
            .map((mapper) => {
              const floor = i18n
                .t("floors")
                .filter((floor) => floor.floorNumber === mapper.mapFloor);
              return {
                ...mapper,
                floorName: floor.length > 0 ? floor[0].floorName : "",
              };
            });
          // console.log(result,"These are results.")
          resolve(result); // Resolve the promise with the result
        } catch (error) {
          reject(error); // Reject the promise if there's an error
        }
      });
    },

    // courtroomLocations() {
    //   console.log("Maps: ", i18n.t("maps"));
    //   return i18n
    //     .t("maps")
    //     .filter((map) => map.showonHomepage === true)
    //     .map((mapper) => {
    //       const floor = i18n
    //         .t("floors")
    //         .filter((floor) => floor.floorNumber === mapper.mapFloor);
    //       return {
    //         ...mapper,
    //         floorName: floor.length > 0 ? floor[0].floorName : "",
    //       };
    //     })
    // },

    // findJudgeName({state},{judgeWithCourtroomsMaps,judgeLastName}){
    //   console.log(judgeWithCourtroomsMaps,judgeLastName,"Enteries in findJudgeName")
    //   if (!judgeWithCourtroomsMaps || !judgeLastName) {
    //     console.warn("Missing data for finding judge name.");
    //     return "";
    //   }
    //   const judge = judgeWithCourtroomsMaps.find(map =>
    //     map.assignedJudge && map.assignedJudge.toLowerCase().includes(judgeLastName)
    //   );
    //   console.log(judge,"This are matched judges.")
    //   return judge ? judge : judgeLastName;

    // },

    // findJudgeName({ state }, { judgeWithCourtroomsMaps, judgeLastName }) {
    //   return new Promise((resolve) => {
    //     console.log(judgeWithCourtroomsMaps, judgeLastName, "Entries in findJudgeName");

    //     const normalize = (str) => str.toLowerCase().replace(/[^a-z\s]/g, '').split(/\s+/);

    //     if (!judgeWithCourtroomsMaps || !judgeLastName) {
    //       console.warn("Missing data for finding judge name.");
    //       return resolve("");
    //     }

    //     const judge = judgeWithCourtroomsMaps.find(map =>
    //       map.assignedJudge && map.assignedJudge.toLowerCase().includes(judgeLastName)
    //     );

    //     console.log(judge, "These are matched judges.");

    //     resolve(judge ? judge : judgeLastName);
    //   });
    // },
    findMap({ getters }, mapLocation) {
      return new Promise((resolve, reject) => {
        let tempDefaultLocationMap = getters.getMaps.get(
          mapLocation.toLowerCase()
        );
        let defaultLocationMap = i18n
          .t("maps")
          .filter(
            (map) =>
              map.displayPosition === tempDefaultLocationMap.displayPosition
          );

        let defaultLocationFloor = i18n
          .t("floors")
          .filter(
            (floor) => floor.floorNumber === defaultLocationMap[0].mapFloor
          );
        resolve({ map: defaultLocationMap, floor: defaultLocationFloor });
      });
    },

    findJudgeName({ state }, { judgeWithCourtroomsMaps, judgeLastName }) {
      return new Promise((resolve) => {
        console.log(
          judgeWithCourtroomsMaps,
          judgeLastName,
          "Entries in findJudgeName"
        );

        // Function to normalize names (convert to lowercase, remove punctuation, split into words)
        const normalize = (str) =>
          str
            .toLowerCase()
            .replace(/[^a-z\s]/g, "")
            .split(/\s+/);

        // Validate inputs
        if (!judgeWithCourtroomsMaps || !judgeLastName) {
          console.warn(
            "Missing data for finding judge name.",
            judgeLastName,
            judgeWithCourtroomsMaps
          );
          return resolve("");
        }

        // Normalize the search query
        const searchWords = normalize(judgeLastName);

        // Find a judge whose name contains all words from search query
        const judge = judgeWithCourtroomsMaps.find((map) => {
          if (!map.assignedJudge) return false;

          const assignedWords = normalize(map.assignedJudge);
          return searchWords.every((word) => assignedWords.includes(word));
        });

        if (!judge) {
          console.log(
            judgeWithCourtroomsMaps,
            judgeLastName,
            "These are non matched judges."
          );
        }

        // Return the matched judge object or the search query if no match is found
        resolve(judge || judgeLastName);
      });
    },

    verifyFile({ dispatch, getters, state, commit }, targetDateIn) {
      return new Promise((resolve, reject) => {
        var manualId = 0;
        //Fetch all files with todays date in format MM.DD.YY
        let filesOnTargetDate = [];
        let data_file = JSON.stringify({
          userName: "chester-pa",
          apiKey:
            "ars-bb-E4uOcpcLgEIKXd-pY5x9Z-bo!7Wl-mLuQCRTmvhqzi4kYpizFdV!tdJizI?Yb75HVSlpw74t-IodAkfSW3qNk7e2OZ8Vwa-ffojXZ2oWrP-AmV0y1z3?SJKmL",
        });

        const config_files = {
          method: "post",
          url: "https://us-central1-chester-pa-70c3b.cloudfunctions.net/apiV2/getHearingFiles/a763cd056f1b2405788443b7197e0708",
          headers: {
            "Content-Type": "application/json",
          },
          data: data_file,
        };

        const mapPath = i18n
          .t("maps")
          .filter((map) => map.hearingLocationCode !== null);

        let judgeWithCourtroomsMaps;

        dispatch("courtroomLocations").then((mapsforJudges) => {
          // console.log(mapsforJudges,"this are maps for judges")
          judgeWithCourtroomsMaps = mapsforJudges;
          // console.log("CourtroomLocations are Here:", judgeWithCourtroomsMaps);
        });

        // console.log(judgeWithCourtroomsMaps,"This are courtrooms")

        // Preprocess the floors array into a lookup object

        const floorLookup = i18n.t("floors").reduce((lookup, floor) => {
          lookup[floor.floorNumber] = floor.floorName;
          return lookup;
        }, {});

        const floorNumber = mapPath.reduce((acc, map) => {
          if (map.mapFloor in floorLookup) {
            acc[map.mapName] = floorLookup[map.mapFloor];
          }
          return acc;
        }, {});

        // Fetch files using Axios
        axios(config_files)
          .then((response) => {
            let temp = [];
            const data = response.data;

            // Extract files with modifiedTime on "2024-12-12"
            // const todaysDate = new Date().toISOString().split("T")[0];

            // const customdate = "10.8.24";

            // commit("setLastFetched", response.data.Header.Time);
            const today = new Date();
            const todayFormatted = `${(today.getMonth() + 1)
              .toString()
              .padStart(2, "0")}.${today
              .getDate()
              .toString()
              .padStart(2, "0")}.${today.getFullYear().toString().slice(-2)}`;

            const todayFormatted2 = `${
              today.getMonth() + 1
            }.${today.getDate()}.${today.getFullYear().toString().slice(-2)}`;
            // filesOnTargetDate = data.fileDetails.filter((file) => {
            //   const fileDate = new Date(file.modifiedTime)
            //     .toISOString()
            //     .split("T")[0];
            //   return fileDate === targetDateIn;
            // });

            filesOnTargetDate = data.fileDetails.filter((file) => {
              // const fileDate = new Date(file.modifiedTime).toISOString().split("T")[0];
              // const nameDateMatch = file.name.match(/\d{2}\.\d{2}\.\d{2}/);
              const nameDateMatch = file.name.match(/\d{1,2}\.\d{1,2}\.\d{2}/);
              const fileNameDate = nameDateMatch ? nameDateMatch[0] : null;

              // Check both conditions
              return (
                fileNameDate === todayFormatted ||
                fileNameDate === todayFormatted2
                // fileNameDate === customdate
              );
            });

            console.log(filesOnTargetDate, "Files on target Date");

            if (filesOnTargetDate && filesOnTargetDate.length > 0) {
              filesOnTargetDate.forEach((file) => {
                console.log(file, "This is the file for todays hearing");
                let data = JSON.stringify({
                  userName: "chester-pa",
                  filePath: file.name,
                  apiKey:
                    "ars-bb-E4uOcpcLgEIKXd-pY5x9Z-bo!7Wl-mLuQCRTmvhqzi4kYpizFdV!tdJizI?Yb75HVSlpw74t-IodAkfSW3qNk7e2OZ8Vwa-ffojXZ2oWrP-AmV0y1z3?SJKmL",
                });

                const config = {
                  method: "post",
                  url: "https://us-central1-chester-pa-70c3b.cloudfunctions.net/apiV2/getHearingDetails/a763cd056f1b2405788443b7197e0708",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: data,
                };

                axios(config)
                  .then((response) => {
                    let hearingObj;

                    // console.log(response.data,": This is the data i want to look");

                    const parsedData = Papa.parse(response.data, {
                      header: false, // Set to `true` if the data includes headers
                      skipEmptyLines: true,
                    });

                    const rows = parsedData.data;

                    // const filenamePrefix = file.name.split(/[^a-zA-Z]/)[0].toLowerCase();
                    // dispatch('findJudgeName', {
                    //   judgeWithCourtroomsMaps,
                    //   judgeLastName: file.name.split(" ")[1].toLowerCase()
                    // }).then(mapDetails => {

                    //   console.log("Resolved Judge Name:", mapDetails);
                    // });

                    if (file.name.split(" ")[0].toLowerCase() === "cpcms") {
                      const regex = /Judge\s+([A-Za-z.\s]+)/g;
                      // console.log("Resolved Judge Name:", mapDetails);
                      rows.forEach((element) => {
                        console.log(element[25], "inside cpcms judge name");

                        const parts = element[25].trim().split(" ");
                        const lastPart = parts.pop();
                        console.log(lastPart, "this is last part of judges");

                        dispatch("findJudgeName", {
                          judgeWithCourtroomsMaps,
                          judgeLastName: lastPart,
                        }).then((mapDetails) => {
                          if (!mapDetails) {
                            console.log(
                              lastPart,
                              "This are the names not getting map details."
                            );
                          }

                          hearingObj = {
                            id: manualId++,
                            hearingLocation: mapDetails.mapName || element[29],
                            isAJudge: /judge/i.test(element[25]),
                            judgeName: /judge/i.test(element[25])
                              ? mapDetails.assignedJudge ||
                                (element[25].match(/judge\s+([A-Za-z.\s]+)/i) ||
                                  [])[1] ||
                                element[25]
                              : element[25]
                                  .replace(/Hearing Officer\s+/i, "")
                                  .trim(),

                            // judgeName: mapDetails.assignedJudge
                            // || (element[25].match(/judge\s+([A-Za-z.\s]+)/i) || [])[1] ? element[25].match(/judge\s+([A-Za-z.\s]+)/i)[1] : element[25],
                            caseNumber: element[30],
                            hearingTime: element[24],
                            // partyName: element[31].toLowerCase().startsWith('Comm') ? element[31].split(' v. ')[1]?.split(',')[0]?.trim() : element[31],
                            floorName: mapDetails.floorName,
                            partyName: element[31].substring(8),
                            // isAJudge: /judge/i.test(element[25])
                            // floorName: floorNumber[element[22]],
                          };
                          console.log("Hearing Obj:", hearingObj);
                          if (
                            hearingObj.caseNumber !== "" ||
                            hearingObj.partyName !== ""
                          ) {
                            const exists = temp.some(
                              (item) =>
                                item.caseNumber === hearingObj.caseNumber &&
                                item.partyName === hearingObj.partyName
                            );
                            if (!exists) {
                              temp.push(hearingObj);
                            }
                          } else {
                            // console.log(
                            //   hearingObj,
                            //   "This object didn't pushed because of no case number or partyName"
                            // );
                          }
                        });
                      });
                    } else if (
                      file.name.split(" ")[0].toLowerCase() === "cv" ||
                      file.name.split(" ")[0].toLowerCase() === "ca"
                    ) {
                      rows.forEach((element) => {
                        console.log(element, "inside ca/cv");

                        const parts = element[25].trim().split(" ");
                        const lastPart = parts.pop();

                        dispatch("findJudgeName", {
                          judgeWithCourtroomsMaps,
                          judgeLastName: lastPart,
                        }).then((mapDetails) => {
                          if (!mapDetails) {
                            console.log(
                              lastPart,
                              "This are the names not getting map details."
                            );
                          }
                          hearingObj = {
                            id: manualId++,
                            hearingLocation: mapDetails.mapName || element[29],
                            isAJudge: /judge/i.test(element[25]),
                            judgeName: /judge/i.test(element[25])
                              ? mapDetails.assignedJudge ||
                                (element[25].match(/judge\s+([A-Za-z.\s]+)/i) ||
                                  [])[1] ||
                                element[25]
                              : element[25]
                                  .replace(/Hearing Officer\s+/i, "")
                                  .trim(),

                            // judgeName: mapDetails.assignedJudge
                            // || (element[25].match(/judge\s+([A-Za-z.\s]+)/i) || [])[1] ? element[25].match(/judge\s+([A-Za-z.\s]+)/i)[1] : element[25],
                            caseNumber: element[30],
                            hearingTime: element[24],
                            // partyName: element[31].toLowerCase().startsWith('Comm') ? element[31].split(' v. ')[1]?.split(',')[0]?.trim() : element[31],
                            floorName: mapDetails.floorName,
                            partyName: element[31],
                            // isAJudge: /judge/i.test(element[25])
                            // floorName: floorNumber[element[22]],
                          };
                          console.log("Hearing Obj:", hearingObj);
                          if (
                            hearingObj.caseNumber !== "" ||
                            hearingObj.partyName !== ""
                          ) {
                            const exists = temp.some(
                              (item) =>
                                item.caseNumber === hearingObj.caseNumber &&
                                item.partyName === hearingObj.partyName
                            );
                            if (!exists) {
                              temp.push(hearingObj);
                            }
                          } else {
                            // console.log(
                            //   hearingObj,
                            //   "This object didn't pushed because of no case number or partyName"
                            // );
                          }
                        });
                      });
                    } else if (
                      file.name.split(" ")[0].toLowerCase() === "misc"
                    ) {
                      // Hall does not have name
                      rows.forEach((element) => {
                        const parts = element[3].split(" ");
                        const lastPart = parts.pop();
                        dispatch("findJudgeName", {
                          judgeWithCourtroomsMaps,
                          judgeLastName: lastPart,
                        }).then((mapDetails) => {
                          console.log("Element 5: ", element[5]);
                          console.log("Element 3: ", element[3]);

                          hearingObj = {
                            id: manualId++,
                            hearingLocation:
                              element[5] ||
                              element[3].split("")[2].toLowerCase(),
                            judgeName:
                              mapDetails.assignedJudge ||
                              element[3].replace(/^\S+\s*/, ""),
                            caseNumber: element[8],
                            hearingTime: element[6],
                            partyName: element[12],
                            floorName:
                              floorNumber[element[5]] ||
                              floorNumber[
                                element[3].split("")[2].toLowerCase()
                              ],
                          };
                          if (
                            hearingObj.caseNumber !== "" ||
                            hearingObj.partyName !== ""
                          ) {
                            const exists = temp.some(
                              (item) =>
                                item.caseNumber === hearingObj.caseNumber &&
                                item.partyName === hearingObj.partyName
                            );
                            if (!exists) {
                              temp.push(hearingObj);
                            }
                          } else {
                            console.log(
                              hearingObj,
                              "This object didn't pushed because of no case number or partyName"
                            );
                          }
                        });
                      });
                    } else if (
                      file.name.split(" ")[0].toLowerCase() === "icc"
                    ) {
                      dispatch("findJudgeName", {
                        judgeWithCourtroomsMaps,
                        judgeLastName: file.name.split(" ")[1].toLowerCase(),
                      }).then((mapDetails) => {
                        console.log();

                        rows.forEach((element) => {
                          const Timein = element[32].match(
                            /\b\d{1,2}:\d{2} (am|pm)\b/i
                          );
                          hearingObj = {
                            id: manualId++,
                            hearingLocation: mapDetails.mapName,
                            judgeName:
                              mapDetails.assignedJudge ||
                              (element[25].match(/judge\s+([A-Za-z.\s]+)/i) ||
                                [])[1]
                                ? element[25]
                                    .match(/judge\s+([A-Za-z.\s]+)/i)[1]
                                    .trim()
                                : element[25],
                            caseNumber: element[30],
                            hearingTime: Timein,
                            partyName: element[31].substring(8),
                            floorName: mapDetails.floorName,
                          };
                          // console.log(hearingLocation[], "Single elements");
                          if (
                            hearingObj.caseNumber !== "" ||
                            hearingObj.partyName !== ""
                          ) {
                            const exists = temp.some(
                              (item) =>
                                item.caseNumber === hearingObj.caseNumber &&
                                item.partyName === hearingObj.partyName
                            );
                            if (!exists) {
                              temp.push(hearingObj);
                            }
                          } else {
                            console.log(
                              hearingObj,
                              "This object didn't pushed because of no case number or partyName"
                            );
                          }
                        });
                      });

                      // Hall does not have name
                    } else {
                      const badFileData = {
                        filename: file.name,
                      };
                      let config = {
                        method: "post",
                        maxBodyLength: Infinity,
                        url: "https://cms.chester.arsconnect.com/badftpfiles",
                        headers: {
                          // 'Content-Type': 'application/json',
                          // Authorization: `Bearer ${jwtToken}`,
                        },
                        data: badFileData,
                      };
                      axios
                        .request(config)
                        .then((response) => {
                          //console.log(JSON.stringify(response.data));
                          console.log("BAD FTP File Added");
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }
                    console.log(temp.length, "length of temp");
                    commit("setHearingInfo", temp);

                    // fb.kioskCollection.get().then((snapshot) => {
                    //   snapshot.docs.forEach((doc) => {
                    //     // console.log("Data for Each Prod Kiosk:", doc.data());
                    //     // console.log(this.getters.getKioskProfile,"this is the kioskprofile");
                    //     if (doc.id === this.getters.getKioskProfile.id) {
                    //       const modules = doc.data().modules;
                    //       if (modules.includes("hearing")) {
                    //         // console.log(doc.data().name,"Kiosk with hearing module right now");
                    //         fb.kioskCollection.doc(doc.id).update({
                    //           pullFiles: false,
                    //         });
                    //       }
                    //     }
                    //   });
                    // });

                    fb.kioskCollection
                      .doc(getters.getKioskId)
                      .get()
                      .then((doc) => {
                        if (doc.exists) {
                          // const data = doc.data()
                          const modules = doc.data().modules;
                          if (modules.includes("hearing")) {
                            fb.kioskCollection.doc(doc.id).update({
                              pullFiles: false,
                            });
                          }
                        }
                      });

                    resolve(true);
                  })
                  .catch((error) => {
                    resolve(false);
                    if (error.response.status === 404) {
                      console.log("File not present on FTP server");
                    } else if (error.response.status === 403) {
                      console.log("Access problem to FTP Server");
                    }
                  });
              });
            } else {
              console.log("No files to upload today.");
              resolve(true);
            }
          })
          .catch((error) => {
            console.error("Error fetching files:", error.message);
          });
      });
    },

    listenToPullFilesChange({ dispatch }) {
      // fb.kioskCollection.onSnapshot((snapshot) => {
      // snapshot.docChanges().forEach((change) => {
      // if (change.type === "modified") {
      // if (change.doc.data().pullFiles === true) {
      const today = new Date();
      // Take Friday if Sunday else one day before the day.
      today.setDate(today.getDate() - (today.getDay() === 1 ? 3 : 1));
      const datePullFiles = today.toISOString().split("T")[0];
      dispatch("verifyFile", datePullFiles).then((response) => {
        if (response) {
          this.loading = false;
        } else {
          this.$store.dispatch(
            "avatarSpeak",
            "Error fetching hearing data. Please go to the Court Administration Office on 4th floor. "
          );
        }
      });
      // }
      // }
      // });
      // });
    },

    filePullScheduler({ dispatch, getters }) {
      // const currentDate = new Date();
      // const hour = currentDate.getHours();
      // // 7 AM to 6 PM
      // // if (hour === 11) {
      // //     location.reload()
      // // }
      // if (hour >= 7 && hour < 17) {
      //   dispatch("verifyFile");
      //   setTimeout(() => {
      //     dispatch("filePullScheduler");
      //   }, 300000);
      // } else if (hour >= 18) {
      //   dispatch("verifyFile");
      //   setTimeout(() => {
      //     dispatch("filePullScheduler");
      //   }, 3600000);
      // } else {
      //   dispatch("verifyFile");
      //   setTimeout(() => {
      //     dispatch("filePullScheduler");
      //   }, 3600000);
      // }
    },
  },
};
