<template>
  <div>
    <v-row class="mt-3" no-gutters align="center" justify="center">
      <v-col v-if="getenableADA" class="d-flex mt-3 mb-0 justify-center align-center" cols="12">
        <span class="text-h3 font-weight-bold">
          {{ getPageHeader }}
        </span>
      </v-col>

      <v-col cols="6">
        <v-card flat>
          <v-card-text class="d-flex justify-center pa-0">
            <template v-if="!getenableADA">
              <v-text-field :placeholder="$i18n.t('placeHolder')" class="text-h5" hide-details outlined
                height="55px" rounded v-model="searchKeyword" @click="updateExpand()" :append-icon="searchBarIcon"
                clear-icon="mdi-close-circle" @click:append="clearSearch()">
              </v-text-field>
              <template v-if="!getKioskProfile.data().physicalKeyboard">
                <v-icon size="35" class="ml-2" v-if="!expand" @click="expand = !expand">mdi-keyboard-outline</v-icon>
                <v-icon size="35" class="ml-2" v-else @click="
                  expand = !expand;
                clearSearch();
                searchId = '';
                ">mdi-keyboard-off-outline</v-icon>
              </template>
            </template>
          </v-card-text>
          <v-expand-transition>
            <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
              <SimpleKeyboard class="keyboard" @onKeyPress="onKeyPress" @onChange="onChange">
              </SimpleKeyboard>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="3" class="mt-5" align="center" v-if="!expand && searchKeyword === '' && searchId === ''">
            <v-card width="95%" class="containerBorder" :height="cardHeight">
              <v-card-title class="justify-center primary--text">{{
                $t("categoryTitle")
              }}</v-card-title>
              <v-divider class="mx-3"></v-divider>
              <v-list class="overflow-y-auto" :height="cardHeight - 80">
                <v-list-item v-for="(categoryName, i) in faqCategory" :key="i">
                  <v-list-item-content>
                    <v-btn height="45" @click="
                      $store.commit('setSelectedFaqCat', {
                        category: categoryName,
                      })
                      " v-bind:color="getSelectedFaqCat.category === categoryName
                          ? 'secondary'
                          : 'primary'
                        ">{{ categoryName }}</v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col :cols="colLenght" class="mt-5" align="center">
            <v-card width="95%" class="containerBorder overflow-y-auto mb-2" :height="cardHeight">
              <v-expansion-panels focusable dark class="px-5 py-3"
                v-if="!expand && searchKeyword === '' && searchId === ''" popout v-model="panel">
                <v-expansion-panel v-for="(item, i) in filteredFaqs(getSelectedFaqCat.category)" :key="i" class="mt-2"
                  :value="item.question">
                  <v-expansion-panel-header color="primary" class="white--text text-h6" @click="clickedFaq(item)">
                    <template v-slot:actions>
                      <v-icon color="white" large>mdi-arrow-down-bold-circle</v-icon>
                    </template>
                    {{ item.question }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="white" class="black--text pt-4 text-justify text-h6">
                    <v-row class="fill-height" no-gutters v-if="item.showMap || item.showUrl || item.showForm">
                      <v-col cols="9">
                        <span v-html="item.answer"></span>
                      </v-col>

                      <v-col cols="3">
                        <v-row no-gutters>
                          <v-col v-if="item.showForm" align-self="center" align="center">
                            <v-btn color="secondary" class="mb-3" width="85%" @click="showForm(item.formName)">
                              <v-icon left dark medium>
                                mdi-link-variant
                              </v-icon>
                              {{ item.formName }}
                            </v-btn>
                          </v-col>
                          <v-col v-if="
                            item.showMap &&
                            getKioskProfile.data().kioskPremise === 'Inside'
                          " align-self="center" align="center">
                            <div v-for="(map, i) in assignedMaps(item.mapName)" :key="i">
                              <v-btn color="secondary" @click="showMap(map)" class="mb-3" width="85%">
                                <v-icon left dark medium>
                                  mdi-map-marker-radius
                                </v-icon>
                                {{ trimmedName(map) }}
                              </v-btn>
                            </div>
                          </v-col>
                          <v-col v-if="item.showUrl" align-self="center" align="center">
                            <v-btn color="secondary" class="mb-3" width="85%" @click="
                              sendLink(
                                item.urlDetails.urllist[0].link,
                                item.question,
                                item.urlDetails.urllist[0].redirect
                              )
                              ">
                              <v-icon left dark medium>
                                mdi-link-variant
                              </v-icon>
                              <!-- {{trimmedName(item.urlDetails.urllist[0].name)}} -->
                              {{
                                item.urlDetails &&
                                  item.urlDetails.urllist &&
                                  item.urlDetails.urllist.length > 0
                                  ? trimmedName(item.urlDetails.urllist[0].name)
                                  : "Go to Website"
                              }}
                            </v-btn>
                          </v-col>
                          <!-- <v-col
                            v-if="item.showForm"
                            align-self="center"
                            align="center"
                          >
                            <v-btn
                              color="secondary"
                              class="mb-3"
                              width="85%"
                              @click="showForm(item.formName)"
                            >
                              <v-icon left dark medium>
                                mdi-link-variant
                              </v-icon>
                              {{ item.formName }}
                            </v-btn>
                          </v-col> -->
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col>
                        <span v-html="item.answer"></span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels focusable dark class="px-3" popout v-else v-model="panel">
                <v-expansion-panel v-for="(item, i) in matchingFaqs" :key="i" class="mt-2" :value="item.question">
                  <!-- <v-card-text v-if="matchingFaqs.length === 0" class="pa-0">
                    <span class="text-h4"> No matching forms found. </span>
                  </v-card-text> -->

                  <v-expansion-panel-header color="primary" class="white--text text-h6" @click="clickedFaq(item)">
                    <template v-slot:actions>
                      <v-icon color="white" large>mdi-arrow-down-bold-circle</v-icon>
                    </template>
                    {{ item.question }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="white" class="black--text pt-4 text-justify text-h6">
                    <v-row class="fill-height" no-gutters v-if="item.showMap || item.showUrl || item.showForm">
                      <v-col cols="9">
                        <span v-html="item.answer"></span>
                      </v-col>

                      <v-col cols="3">
                        <v-row no-gutters>
                          <v-col v-if="
                            item.showMap &&
                            getKioskProfile.data().kioskPremise === 'Inside'
                          " align-self="center" align="center">
                            <div v-for="(map, i) in assignedMaps(item.mapName)" :key="i">
                              <v-btn color="secondary" @click="showMap(map)" class="mb-3" width="85%">
                                <v-icon left dark medium>
                                  mdi-map-marker-radius
                                </v-icon>
                                {{ trimmedName(map) }}
                              </v-btn>
                            </div>
                          </v-col>
                          <v-col v-if="item.showUrl" align-self="center" align="center">
                            <v-btn color="secondary" class="mb-3" width="85%" @click="
                              sendLink(
                                item.urlDetails.urllist[0].link,
                                item.question,
                                item.urlDetails.urllist[0].redirect
                              )
                              ">
                              <v-icon left dark medium>
                                mdi-link-variant
                              </v-icon>
                              <!-- {{trimmedName(item.urlDetails.urllist[0].name)}} -->
                              {{
                                item.urlDetails &&
                                  item.urlDetails.urllist &&
                                  item.urlDetails.urllist.length > 0
                                  ? trimmedName(item.urlDetails.urllist[0].name)
                                  : "Go to Website"
                              }}
                            </v-btn>
                          </v-col>
                          <v-col v-if="item.showForm" align-self="center" align="center">
                            <v-btn color="secondary" class="mb-3" width="85%" @click="showForm(item.formName)">
                              <v-icon left dark medium>
                                mdi-link-variant
                              </v-icon>
                              {{ item.formName }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col>
                        <span v-html="item.answer"></span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Add this block for the "No Match Found" text -->
                <v-row v-if="matchingFaqs.length === 0" justify="center" class="mt-4">
                  <v-col cols="12" class="text-center pa-0">
                    <span class="text-h4">{{
                      this.$i18n.t("noFaqsMatch")
                    }}</span>
                  </v-col>
                </v-row>
              </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SimpleKeyboard from "@/components/SimpleKeyboard";
import { clearWebGLContext } from "@tensorflow/tfjs-backend-webgl/dist/canvas_util";
const closest_match = require("closest-match");
export default {
  name: "faqs",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      tab: 0,
      input: "",
      searchKeyword: "",
      searchBarIcon: "mdi-magnify",
      expand: false,
      cardHeight: 690,
      colLenght: "9",
      panel: 0,
      searchId: null,
    };
  },
  computed: {
    ...mapGetters([
      "getQnaKeyword",
      "getFaqCategory",
      "getSelectedFaqCat",
      "getKioskProfile",
      "getAvatarLangCodes",
      "getSessionId",
      "getNetworkConnection",
      "getenableADA",
      "getPageHeader",
    ]),
    matchingFaqs() {
      let temp = [];
      let distance;
      if (this.searchKeyword !== "") {
        console.log("Search Keyword:", this.searchKeyword);
        temp = this.$i18n
          .t("faqs")
          .filter((faq) =>
            faq.question
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase())
          );
      } else if (this.searchId !== "") {
        console.log("Searchid empty", this.searchId);
        temp = this.$i18n
          .t("faqs")
          .filter((faq) => faq.linkId.toString() === this.searchId);
      } else {
        temp = this.$i18n.t("faqs");
      }
      return temp;
    },
    faqCategory() {
      return this.$i18n
        .t("faqCategory")
        .map((category) => category.categoryName);
    },
  },
  watch: {
    expand(newVal) {
      if (newVal) {
        this.searchBarIcon = "mdi-close-circle";
        console.log("focused");
        console.log(
          this.getKioskProfile.data().physicalKeyboard,
          "phy key ki val"
        );
        if (this.getKioskProfile.data().physicalKeyboard) {
          this.cardHeight = 690;
        } else {
          this.cardHeight = 450;
        }
        this.colLenght = 12;
      } else {
        console.log("Coming here in else: ", this.searchKeyword);
        if (this.searchKeyword !== "") {
          this.searchBarIcon = "mdi-close-circle";
        } else {
          this.searchBarIcon = "mdi-magnify";
          this.colLenght = 9;
        }
        this.cardHeight = 690;
      }
    },
    searchId(newVal) {
      this.panel = 0;
    },
    getQnaKeyword(newVal) {
      this.searchId = newVal;
      this.colLenght = 12;
      //this.searchKeyword = newVal
    },
    searchKeyword(newVal) {
      console.log("New Value at Search Keyword watch: ", newVal.trim().length);
      if (newVal === "" && newVal.trim().length < 1) {
        this.searchBarIcon = "mdi-magnify";
        console.log("line 432");

        if (this.getKioskProfile.data().physicalKeyboard) {
          this.colLenght = 9;
        }
      } else {
        this.searchBarIcon = "mdi-close-circle";
        this.colLenght = 12;
      }
    },
  },
  methods: {
    trimmedName(name) {
      return name.length > 15 ? name.substring(0, 15) + "..." : name;
    },
    updateExpand() {
      if (this.getKioskProfile.data().physicalKeyboard) {
        this.expand = false;
      } else {
        if (this.expand) {
          this.expand = false;
        } else {
          this.expand = true;
        }
      }
    },
    clearSearch() {
      this.searchKeyword = "";
      this.searchId = "";
      this.$store.commit("setClearInput", true);
    },
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      this.$store.commit("setClearInput", false);
      if (button === "{bksp}")
        this.searchKeyword = this.searchKeyword.slice(0, -1);
    },
    filteredFaqs(category = "") {
      return this.$i18n
        .t("faqs")
        .filter((faq) =>
          (faq.category || "").toLowerCase().includes(category.toLowerCase())
        );
    },

    assignedMaps(maps) {
      let assignedMap = maps.split(",");
      return assignedMap;
    },
    showMap(map) {
      let date = new Date();
      this.$i18n.t("maps").forEach((mapper) => {
        let floorName = this.$i18n
          .t("floors")
          .filter((floor) => floor.floorNumber === mapper.mapFloor);
        if (mapper.mapName.toLowerCase().trim() === map.toLowerCase().trim()) {
          this.$store.dispatch("openImageViewer", {
            name: mapper.mapName,
            url: mapper.mapImage,
            floor: floorName[0].floorName,
          });
          //this.$store.dispatch("avatarSpeak", mapper.speech);
          this.$store.commit("setTextBubbleText", mapper.speech);
          window.sayText(
            mapper.speech,
            this.getAvatarLangCodes.voiceId,
            this.getAvatarLangCodes.langId,
            this.getAvatarLangCodes.engineId
          );
          this.tab = mapper.mapFloor - 1;
          this.$store.dispatch("addSessionDataToStrapi", {
            applicationId: this.getKioskProfile.data().applicationId,
            actionType: "Touch",
            applicationSessionId: this.getSessionId,
            timeStamp: date.toISOString(),
            sessionPayload: {
              module: "Maps",
              action: "View Map",
              response: mapper.mapName,
              timeStamp: date.toISOString(),
            },
          });
        }
      });
    },
    sendLink(url, name, redirect) {
      if (redirect) {
        var date = new Date();
        if (url === "https://ujsportal.pacourts.us/CaseSearch") {
          this.$store.dispatch("addSessionDataToStrapi", {
            applicationId: this.getKioskProfile.data().applicationId,
            actionType: "Touch",
            applicationSessionId: this.getSessionId,
            timeStamp: date.toISOString(),
            sessionPayload: {
              module: "Case Search",
              action: "Visited Chester Web Portal",
              response: `Redirected to : ${url}`,
              timeStamp: date.toISOString(),
            },
          });
        } else {
          this.$store.dispatch("addSessionDataToStrapi", {
            applicationId: this.getKioskProfile.data().applicationId,
            actionType: "Touch",
            applicationSessionId: this.getSessionId,
            timeStamp: date.toISOString(),
            sessionPayload: {
              module: "Pay Online",
              action: "Visited Chester Web Portal",
              response: `Redirected to : ${url}`,
              timeStamp: date.toISOString(),
            },
          });
        }

        // commented this as getting 2 session ended
        // this.$store.dispatch("addSessionDataToStrapi", {
        //   applicationId: this.getKioskProfile.data().applicationId,
        //   actionType: "Session Ended",
        //   applicationSessionId: this.getSessionId,
        //   timeStamp: date.toISOString(),
        //   sessionPayload: {},
        // });

        //redirect to chester PA Website
        // this.$store.commit("setIsThinking", false)
        // this.$store.commit("setSpeechRecognitionHandler", false);
        // window.location.href = url

        // window.open(url, "_blank");
        if (this.getNetworkConnection) {
          window.open(url, "_blank");
        } else {
          this.$store.commit(
            "setTextBubbleText",
            this.$i18n.t("featureDisabledMsg")
          );
        }
      } else {
        this.$store.commit("setRequestQna", false);
        this.$store.commit("setIsThinking", false);
        this.$store.commit("setSpeechRecognitionHandler", false);
        this.$store.dispatch(
          "avatarSpeak",
          this.$i18n.t(
            "Please enter your Email ID or scan the QR Code to get this link."
          )
        );
        this.$store.dispatch("openEmailViewer", {
          pdfType: "link",
          pdfName: name,
          pdfUrl: url,
        });
      }

      // this.$store.dispatch(
      //   "avatarSpeak",
      //   "Please enter your Email ID or scan the QR Code to get this link."
      // );
      // // console.log("inside this sendLink",url,name);
      // this.$store.commit(
      //   "setTextBubbleText",
      //   "Please enter your Email ID or scan the QR Code to get this link."
      // );

      // window.setPlayerVolume(0)
      // window.stopSpeech()

      // window.sayText(
      //   "Please enter your Email ID or scan the QR Code to get this link.",
      //   this.getAvatarLangCodes.voiceId,
      //   this.getAvatarLangCodes.langId,
      //   this.getAvatarLangCodes.engineId
      // );
      // this.$store.dispatch("openEmailViewer", {
      //   pdfType: "link",
      //   pdfName: name,
      //   pdfUrl: url,
      // });
    },
    showForm(name) {
      console.log(name, "inside form");
      let temForm = this.$i18n
        .t("forms")
        .filter((form) => form.formName.toLowerCase() === name.toLowerCase());
      console.log(temForm);
      this.$store.dispatch("openPdfViewer", {
        url: temForm[0].document,
        name: temForm[0].formName,
        emailLink: temForm[0].documentUrl,
        type: temForm[0].documentType,
      });
    },
    // Touch Usage
    clickedFaq(faq) {
      //this.category = faq.category
      const date = new Date();
      //  faq.showForm = true; // Ensure `showForm` is being set correctly
      //   faq.formName = "Form Name";
      console.log(faq, "this is the clicked faq");
      console.log(faq.showForm, "this is the val for form");
      console.log(faq.formName, "Value of FormName");
      this.$store.dispatch("addSessionDataToStrapi", {
        applicationId: this.getKioskProfile.data().applicationId,
        actionType: "Touch",
        applicationSessionId: this.getSessionId,
        timeStamp: date.toISOString(),
        sessionPayload: {
          module: "Frequently Asked Question",
          action: "Show Faq",
          response: faq.question,
          timeStamp: date.toISOString(),
        },
      });
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 90) {
        return val;
      }
      return `${val.substring(0, 70)}...`;
    },
  },
  mounted() {
    // Search Faq by keyword
    // this.searchKeyword = this.getQnaKeyword
    this.searchId = this.getQnaKeyword;
    if (this.searchKeyword !== "" || this.searchId !== "") {
      this.colLenght = 12;
    }
  },
};
</script>

<style>
.tabClass {
  background-color: #d37444 !important;
  /* background-color: #9F2B00 !important;  */
  color: white !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.containerBorder {
  border-width: 3px;
  border-style: solid;
  border-color: #2c6b87 !important;
  border-radius: 0px !important;
}

.PDFborder {
  border-style: solid;
  border-width: 2px;
  border-color: #2c6b87 !important;
  overflow-y: scroll;
  border-radius: 15px !important;
}

.linkCardBorder {
  border-color: #2c6b87 !important;
  border-bottom: solid;
  color: white !important;
  border-radius: 0px !important;
  margin-bottom: 2px;
  border-width: 0.5px;
}
</style>
<i18n>
  {
    "en": {
        "placeHolder": "Enter a keyword to search for FAQ",
        "categoryTitle": "CATEGORIES",
        "featureDisabledMsg": "This feature is currently unavailable due to internet connectivity issue.",
        "noFaqsMatch":"No Matching Faqs Found"
      },
    "es":{
        "placeHolder": "Introduzca una palabra clave para buscar preguntas frecuentes",
        "categoryTitle": "Categorías",
        "featureDisabledMsg" : "Esta función no está disponible actualmente debido a un problema de conectividad a Internet.",
        "noFaqsMatch":"No se han encontrado Faqs coincidentes"
      }
  }
</i18n>