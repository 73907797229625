import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "./store";
Vue.use(VueI18n);

// The getters are to be called in the way below only when Map Datastructure is used.
let getHomepageEn = () => {
  return store.getters.getHomepage.filter((page) => page.locale === "en");
};

let getHomepageEs = () => {
  return store.getters.getHomepage.filter((page) => page.locale === "es-US");
};

// Getters for Faq Category -> English Language
let getFaqCategoriesEn = () => {
  return store.getters.getFaqCategories.filter((cat) => cat.locale === "en");
};

let getFaqCategoriesEs = () => {
  return store.getters.getFaqCategories.filter((cat) => cat.locale === "es-US");
};

let getFaqsEn = () => {
  return store.getters.getFaqs.filter((faq) => faq.locale === "en");
};

let getFaqsWithUrlEn = () => {
  console.log(store.getters.getFaqsWithUrl);
  return store.getters.getFaqsWithUrl.filter((faq) => faq.locale === "en");
};

let getFaqsWithUrlEs = () => {
  console.log(store.getters.getFaqsWithUrl);
  return store.getters.getFaqsWithUrl.filter((faq) => faq.locale === "es-US");
};

let getFaqsEs = () => {
  return store.getters.getFaqs.filter((faq) => faq.locale === "es-US");
};

let getFloorsEn = () => {
  return store.getters.getFloors.filter((floor) => floor.locale === "en");
};

let getFloorsEs = () => {
  return store.getters.getFloors.filter((floor) => floor.locale === "es-US");
};

// Getters for Maps -> English Language
let getMapsEn = () => {
  let temp = [];
  store.getters.getMaps.forEach((map) => {
    if (map.locale === "en") {
      temp.push(map);
    }
  });
  return temp;
};

let getMapsEs = () => {
  let temp = [];
  store.getters.getMaps.forEach((map) => {
    if (map.locale === "es-US") {
      temp.push(map);
    }
  });
  return temp;
};

let getFormCategoriesEn = () => {
  return store.getters.getFormCategories.filter(
    (category) => category.locale === "en"
  );
};

let getFormCategoriesEs = () => {
  return store.getters.getFormCategories.filter(
    (category) => category.locale === "es-US"
  );
};

let getQnaEn = () => {
  console.log(store.getters.getQnaEndpointEN.kbName, "check this ");
  return store.getters.getQnaEndpointEN.kbName;
};
let getQnaEs = () => {
  return store.getters.getQnaEndpointES.kbName;
};

let getFormsEn = () => {
  let temp = [];
  store.getters.getForms.forEach((form) => {
    if (form.locale === "en") {
      temp.push(form);
    }
  });
  return temp;
};

let getFormsEs = () => {
  let temp = [];
  store.getters.getForms.forEach((form) => {
    console.log(form, "This are forms");
    console.log(form.locale, "This is locale");
    if (form.locale === "es-US") {
      temp.push(form);
    }
  });
  return temp;
};

let getDepartmentsEn = () => {
  return store.getters.getDepartments.filter(
    (depart) => depart.locale === "en"
  );
};

let getDepartmentsEs = () => {
  return store.getters.getDepartments.filter(
    (depart) => depart.locale === "es-US"
  );
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",

  messages: {
    en: {
      // Homepage
      homepages: getHomepageEn,

      // Faqs
      faqs: getFaqsEn,
      faqsWithUrl: getFaqsWithUrlEn,

      faqCategory: getFaqCategoriesEn,

      // Maps
      maps: getMapsEn,
      floors: getFloorsEn,

      // Forms
      formCategory: getFormCategoriesEn,
      forms: getFormsEn,

      departments: getDepartmentsEn,

      qnaEndpoint: getQnaEn,
      currentLang: "en",
      kbError:
        "Sorry I am still learning. Please visit someone at the window for assistance.",
      defaultKBError:
        "Sorry I am still learning. Please visit the Court Administration Office for more Information.",
      mdjdefaultError:
        "Sorry I am still learning. Please see someone at the Counter",
      mapNotFound:
        "Sorry, I can't find the requested location, please go to the Law Library for more information.",
      formNotFound:
        "Sorry, I can't find the requested form, please go to the Law Library for more information.",
      selectOptionMsg:
        "Tap on the below, or click on the microphone to ask another question.",
      bubbletextafteraction:
        "Tap on the microphone button, and ask a court or county related question.",
      defaultfloor:"Floor 4"
    },
    es: {
      //Homepage
      homepages: getHomepageEs,
      // Faqs
      faqs: getFaqsEs,
      faqsWithUrl: getFaqsWithUrlEs,
      faqCategory: getFaqCategoriesEs,

      // Maps
      maps: getMapsEs,
      floors: getFloorsEs,

      // Forms
      formCategory: getFormCategoriesEs,
      forms: getFormsEs,

      departments: getDepartmentsEs,

      qnaEndpoint: getQnaEs,
      currentLang: "es-US",
      kbError:
        "Lo siento, todavía estoy aprendiendo. Visite a alguien en la ventana para obtener ayuda.",
      defaultKBError:
        "Lo siento, todavía estoy aprendiendo. Visite la Oficina de Administración del Tribunal para obtener más información.",
      mdjdefaultError:
        "Lo siento, todavía estoy aprendiendo. Por favor, consulte a alguien en el mostrador.",
      mapNotFound: "mdj error in spanish",
      formNotFound:
        "Lo siento, no puedo encontrar el formulario solicitado, vaya a la Biblioteca de Derecho para obtener más información.",
      selectOptionMsg:
        "Toque a continuación o haga clic en el micrófono para hacer otra pregunta.",
      bubbletextafteraction:
        "Toque el botón del micrófono y haga una pregunta relacionada con el tribunal o el condado.",
        defaultfloor:"Piso 4"
    },
  },
});
